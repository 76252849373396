import axios from "axios";

export const getEventById = async (id) => {
    try {
        const req = await axios.get(
            `https://crm-api.internal.aden.org/api/v1/event/${id}`
        );
        return req.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const postAttended = async (eventId, email) => {
    try {
        const req = await axios.post(
            `https://crm-api.internal.aden.org/api/v1/event/attendance/${eventId}?email=${email}`
        );
        return req.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const postAttendedRegister = async (body) => {
    const headers = {
        "Content-Type": "application/json",
    };
    try {
        const req = await axios.post(
            `https://crm-api.internal.aden.org/api/v1/event/register-event`,
            body,
            { headers: headers }
        );
        return req.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCountries = async () => {
    try {
        const req = await axios.get(
            `https://sisapi.aden.org/api/v1/acropolis/miscs/countries`
        );
        return req.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};
