import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import DefaultField from "./common/DefaultField";
import { existingSchema, notExistingSchema } from "../utils/schemas";
import { Icon } from "@iconify/react";
import {
    getCountries,
    postAttended,
    postAttendedRegister,
} from "../api/events";
import DefaultComplete from "./common/DefaultComplete";
import InputPhone from "./common/InputPhone";

const FormComponent = (props) => {
    const { event } = props;

    const [existingContact, setExistingContact] = useState(true);
    const [confirmation, setConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState(null);

    // EFFECTS
    useEffect(() => {
        countries === null && handleCountries();
    }, [countries]);

    // FUNCTIONS
    const handleCountries = async () => {
        try {
            const country = await getCountries();

            setCountries(country);
        } catch (error) {
            setCountries(false);
        }
    };

    console.log("existingContact", existingContact);

    const initialValues = existingContact
        ? {
              email: "",
          }
        : {
              email: "",
              name: "",
              lastName: "",
              country: "",
              phone: "",
          };

    const handleSubmit = async (e) => {
        setLoading(true);
        try {
            if (existingContact) {
                const response = await postAttended(event.crm_id, e.email);

                if (!!response?.error) {
                    console.log("entre aca");
                    setExistingContact(false);
                    setConfirmation(false);
                    setLoading(false);
                } else {
                    setExistingContact(true);
                    setConfirmation(true);
                    setLoading(false);
                }
            } else {
                await postAttendedRegister({
                    event_id: event?.crm_id,

                    registration_data: {
                        name: e.name,
                        lastname: e.lastName,
                        email: e.email,
                        phone: e.phone,
                        country: e?.country?.code,
                    },
                });

                setExistingContact(true);
                sendContact(e?.email);
            }
        } catch (error) {
            console.log("aca 2");
            setExistingContact(false);
            setConfirmation(false);
            setLoading(false);
        }
    };

    const sendContact = async (email) => {
        try {
            await postAttended(event?.crm_id, email);

            setExistingContact(true);
            setConfirmation(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setConfirmation(false);
        }
    };

    return (
        <Wrapper>
            {!confirmation && (
                <h4>INGRESA EL MAIL CON EL QUE TE INSCRIBISTE AL EVENTO</h4>
            )}
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={
                    existingContact ? existingSchema : notExistingSchema
                }
            >
                {({ values, setFieldValue }) => (
                    <Container>
                        {!confirmation && (
                            <DefaultField
                                name="email"
                                type="text"
                                label="E-MAIL"
                            />
                        )}
                        {existingContact && (
                            <CustomButton
                                disabled={confirmation}
                                confirmation={confirmation}
                                type="submit"
                            >
                                {loading
                                    ? "enviando asistencia..."
                                    : confirmation
                                    ? "Asistencia confirmada"
                                    : "Marcar asistencia"}
                            </CustomButton>
                        )}
                        {!existingContact && (
                            <FormContainer>
                                <Warning>
                                    <IconWarning icon="ph:warning-light" />
                                    <p>
                                        ¡Upss! No estás inscripto en el evento,
                                        completa los campos a continuación para
                                        registrarte y confirmar asistencia.
                                    </p>
                                </Warning>
                                <Row>
                                    <DefaultField
                                        name="name"
                                        type="text"
                                        label="NOMBRE"
                                    />
                                    <DefaultField
                                        name="lastName"
                                        type="text"
                                        label="APELLIDO"
                                    />
                                </Row>
                                {/* <DefaultField
                                    name="country"
                                    type="text"
                                    label="PAÍS"
                                /> */}

                                <DefaultComplete
                                    name="country"
                                    requiredType={true}
                                    label="PAÍS"
                                    value={values.country}
                                    setFieldValue={setFieldValue}
                                    options={countries}
                                />
                                {/* <DefaultField
                                    name="phone"
                                    type="number"
                                    label="NÚMERO DE TELÉFONO"
                                /> */}

                                <InputPhone
                                    name="phone"
                                    label="NÚMERO DE TELÉFONO"
                                    value={values.phone}
                                    setFieldValue={setFieldValue}
                                    variant="outlined"
                                    required={true}
                                />
                                <CustomButton
                                    disabled={confirmation}
                                    fullWidth
                                    confirmation={confirmation}
                                    type="submit"
                                >
                                    {loading
                                        ? "enviando asistencia..."
                                        : confirmation
                                        ? "Asistencia confirmada"
                                        : "Registrarme y marcar asistencia"}
                                </CustomButton>
                            </FormContainer>
                        )}
                    </Container>
                )}
            </Formik>
        </Wrapper>
    );
};

export default FormComponent;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;

    h4 {
        color: #b31d15;
        font-size: 1rem;
        text-align: center;
    }
`;

const Container = styled(Form)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
`;

const CustomButton = styled.button`
    width: ${(p) => (p.fullWidth ? "100%" : "fit-content")};
    background-color: ${(p) => (!p.confirmation ? "#b31d15" : "#25c159")};
    color: #fff;
    font-size: 0.95rem;
    font-weight: bold;
    border: none;
    border-radius: 30px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 1rem 1.5rem;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
`;
const Warning = styled.div`
    display: flex;
    gap: 0.3rem;
    align-items: center;

    p {
        color: #1d1d1b;
        font-size: 1rem;
    }
`;

const IconWarning = styled(Icon)`
    font-size: 4rem;
    color: #b31d15;
`;

const Row = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;
